






import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import RouteNames from "@/router/RouteNames";
import Employees from "@/components/payroll/business/employments/employee/Employees.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {Employees},
	methods: {
		RouteNames() {
			return RouteNames
		}
	}
})
export default class PayrollBusinessEmployees extends Vue {
	goToNext(employee: EmployeeDto){
		this.$router.push({name: this.getNextRouteName(),
			params: {companyId: this.$route.params.companyId, personId: `${employee.details.id}`}});
	}

	getNextRouteName(){
		switch (this.$route.params.then){
			case PayrollFileCategories.PAYSTUB: return RouteNames.PAYROLL_BUSINESS_PAYSTUBS;
			case PayrollFileCategories.F_W2: return RouteNames.PAYROLL_BUSINESS_FORMS_W2;
			case PayrollFileCategories.DETAILS: return RouteNames.PAYROLL_BUSINESS_DETAILS;
		}
	}

}
